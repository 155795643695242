import { BodyAccount } from "../../_styled/dashboard-components";
import Navbar from "../../_layout/_navbar/NavbarComponent";
import DefaultHeader from "../../_layout/_headers/DefaultHeader";
import { useEffect } from "react";
import { useState } from "react";
import { paymentsRequest } from "../../../static/request/production.request";
import { Redirect } from "react-router-dom";

export default function PaymentPage() {
  const [userAccount, setUserAccount] = useState('');
  const [selectedTransferencia, setSelectedTransferencia] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [value, setValue] = useState('');
  const [currency, setCurrency] = useState('');
  const [description, setDescription] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const userId = JSON.parse(localStorage.getItem("user"))?._id;
  const userName = JSON.parse(localStorage.getItem("user"))?.username;

  useEffect(() => {
    if (userName) {
      setTimeout(() => {
        let i = 0;
        const intervalId = setInterval(() => {
          setUserAccount(userName.slice(0, i));
          i++;
          if (i > userName.length) {
            clearInterval(intervalId);
          }
        }, 100);
      }, 400);
    }
  }, [userName]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file ? file.name : '';
    setFileName(fileName);
    setIsFileUploaded(false);

    if (formErrors.fileName) {
      setFormErrors((prevErrors) => ({ ...prevErrors, fileName: '' }));
    }
  };

  const handleFileDelete = () => {
    setFileName('');
    setFileSize('');
    setIsFileUploaded(false);
  };

  const handleUserAccountChange = (e) => {
    setUserAccount(e.target.value);

    if (formErrors.userAccount) {
      setFormErrors((prevErrors) => ({ ...prevErrors, userAccount: '' }));
    }
  };

  const handleSelectedTransferenciaChange = (e) => {
    setSelectedTransferencia(e.target.value);

    if (formErrors.selectedTransferencia) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        selectedTransferencia: '',
      }));
    }
  };

  const handleSelectedDateChange = (e) => {
    setSelectedDate(e.target.value);

    if (formErrors.selectedDate) {
      setFormErrors((prevErrors) => ({ ...prevErrors, selectedDate: '' }));
    }
  };

  const handleValueChange = (e) => {
    const value = e.target.value;
    setValue(value);

    if (formErrors.value) {
      setFormErrors((prevErrors) => ({ ...prevErrors, value: '' }));
    }
  };

  const handleCurrencyChange = (e) => {
    const currency = e.target.value;
    setCurrency(currency);

    if (formErrors.currency) {
      setFormErrors((prevErrors) => ({ ...prevErrors, currency: '' }));
    }
  };

  const handleDescriptionChange = (e) => {
    const description = e.target.value;
    setDescription(description);

    if (formErrors.description) {
      setFormErrors((prevErrors) => ({ ...prevErrors, description: '' }));
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!userAccount) {
      errors.userAccount = 'Por favor, completa este campo.';
    }

    if (!selectedTransferencia) {
      errors.selectedTransferencia =
        'Por favor, completa el campo de tipo de transferencia.';
    }

    if (!fileName) {
      errors.fileName = 'Por favor, adjunta una captura de pantalla.';
    }

    if (!selectedDate) {
      errors.selectedDate = 'Por favor, completa la fecha en el cual se realizó.';
    }

    if (!value) {
      errors.value = 'Por favor, completa el campo de valor.';
    } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
      errors.value = 'El campo de valor tiene que ser un monto verdadero.';
    }

    if (!currency) {
      errors.currency = 'Por favor, selecciona una moneda.';
    } else if (!['PEN', 'USD'].includes(currency)) {
      errors.currency = 'La moneda seleccionada no es válida.';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const queryParams = {
      user_identifier: userId,
      paymentGateway: selectedTransferencia,
      paymentCaptureMethod: fileName,
      amount: value,
      paymentCurrency: currency,
      paymentDate: selectedDate,
      comments: description,
    };

    if (validateForm()) {
      try {
        const res = await paymentsRequest.post('/', queryParams);
        setUploadSuccess(true);

        setTimeout(() => {
          window.location.href = '/home#transactions';
          const invoicesSection = document.getElementById('transactions');
          invoicesSection.scrollIntoView({ behavior: 'smooth' });
        }, 2000);
      } catch (error) {
        console.error(error);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          formSubmit: 'Hubo un error al enviar el formulario.',
        }));
      }
    }
  };

  if (!userId) {
    return <Redirect replace to="/login" />;
  }


  return (
    <BodyAccount>
      <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <Navbar />
        <div class="flex-lg-1 h-screen overflow-y-lg-auto">
          <header class="position-sticky top-0 overlap-10 bg-surface-primary border-bottom">
            <div class="container-fluid py-4">
              <div class="row align-items-center">
                <div class="col">
                  <div class="d-flex align-items-center gap-4">
                    <div>
                      <button type="button" class="btn-close text-xs" aria-label="Close"></button>
                    </div>
                    <div class="vr opacity-20 my-1"></div>
                    <h1 class="h4 ls-tight">Registro de transferencia</h1>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="hstack gap-2 justify-content-end">
                    <a href="/support" class="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block">
                      <i class="bi bi-question-circle-fill"></i>
                      <span class="d-none d-sm-inline ms-2">¿Necesitas ayuda?</span>
                    </a>
                    {!uploadSuccess && (
                      <a href='/payment' type="button" class="btn btn-sm btn-neutral border-base d-none d-md-block">
                        <span>Generar uno nuevo</span>
                      </a>
                    )}
                    {!uploadSuccess && (
                      <button type="button" onClick={handleFormSubmit} class="btn btn-sm btn-primary">
                        <span>Enviar</span>
                      </button>
                    )}
                    {uploadSuccess && (
                      <p style={{ fontSize: '12px', color: 'green' }}> ¡La carga de datos fue enviada exitosamente!</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </header>
          {/* PAYMENT V1 -> V2 IN JULY */}
          <main class="py-6 bg-surface-secondary">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-8">
                  <div class="vstack gap-8 mt-4">
                    <input
                      type="text"
                      className="form-control"
                      style={{
                        fontSize: '2rem',
                        fontWeight: 'bold',
                        padding: '0.5rem',
                        outline: 'none',
                        border: 'none',
                        backgroundColor: 'transparent',
                        color: '#000',
                      }}
                      placeholder="Ingresa tu nombre..."
                      value={userAccount}
                      onChange={handleUserAccountChange}
                      readOnly
                    />
                    {formErrors.userAccount && (
                      <p style={{ color: "red" }}>{formErrors.userAccount}</p>
                    )}
                    <div class="card">
                      <div>
                        <div class="textarea-autosize p-6">
                          <textarea class="form-control border-0 shadow-none p-0" rows="4" placeholder="¿Te gustaría añadir algún detalle adicional?" oninput="this.parentNode.dataset.replicatedValue = this.value" onChange={handleDescriptionChange}></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="ps-6 border-start">
                      <h6 class="mb-4">Medio de pago</h6>
                      <div className="vstack gap-3">
                        <div>
                          <div className="form-check">
                            <input
                              className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                              type="radio"
                              name="transferencia"
                              id="transferenciaPayPal"
                              value="PayPal"
                              checked={selectedTransferencia === 'PayPal'}
                              onChange={handleSelectedTransferenciaChange}
                            />
                            <label
                              className="form-check-label font-regular ms-2"
                              htmlFor="transferenciaPayPal"
                            >
                              Realicé una transferencia a través de PayPal
                            </label>
                          </div>
                        </div>
                        <div>
                          <div className="form-check">
                            <input
                              className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                              type="radio"
                              name="transferencia"
                              id="transferenciaYape"
                              value="Yape"
                              checked={selectedTransferencia === 'Yape'}
                              onChange={handleSelectedTransferenciaChange}
                            />
                            <label
                              className="form-check-label font-regular ms-2"
                              htmlFor="transferenciaYape"
                            >
                              Realicé una transferencia a través de Yape
                            </label>
                          </div>
                        </div>
                        <div>
                          <div className="form-check">
                            <input
                              className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                              type="radio"
                              name="transferencia"
                              id="transferenciaBCP"
                              value="BCP"
                              checked={selectedTransferencia === 'BCP'}
                              onChange={handleSelectedTransferenciaChange}
                            />
                            <label
                              className="form-check-label font-regular ms-2"
                              htmlFor="transferenciaBCP"
                            >
                              Realicé una transferencia a través de BCP
                            </label>
                          </div>
                        </div>
                        <div>
                          <div className="form-check">
                            <input
                              className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                              type="radio"
                              name="transferencia"
                              id="transferenciaBBVA"
                              value="BBVA"
                              checked={selectedTransferencia === 'BBVA'}
                              onChange={handleSelectedTransferenciaChange}
                            />
                            <label
                              className="form-check-label font-regular ms-2"
                              htmlFor="transferenciaBBVA"
                            >
                              Realicé una transferencia a través de BBVA
                            </label>
                          </div>
                        </div>
                      </div>
                      {formErrors.selectedTransferencia && (
                        <p style={{ fontSize: '12px', paddingTop: '10px', color: 'red' }}>{formErrors.selectedTransferencia}</p>
                      )}
                    </div>
                    <div className="mb-6">
                      <h6 className="mb-3">Captura de pantalla</h6>
                      <div className="card">
                        <div className="card-body pb-0">
                          <div className="rounded border-2 border-dashed border-primary-hover position-relative">
                            <div className="d-flex justify-content-center px-5 py-5">
                              <label htmlFor="file_upload" className="position-absolute w-full h-full top-0 start-0 cursor-pointer">
                                <input id="file_upload" name="file_upload" type="file" className="visually-hidden" onChange={handleFileChange} />
                              </label>
                              <div className="text-center">
                                <div className="text-2xl text-muted">
                                  <i className="bi bi-upload"></i>
                                </div>
                                <div className="d-flex text-sm mt-3">
                                  <p className="font-semibold">{fileName ? fileName : "Upload a file or drag and drop"}</p>
                                </div>
                                <p className="text-xs text-gray-500">PNG, JPG, GIF up to 3MB</p>
                              </div>
                            </div>
                          </div>
                          {formErrors.fileName && (
                            <p style={{ fontSize: '12px', paddingTop: '10px', color: 'red' }}>{formErrors.fileName}</p>
                          )}
                          <div className="list-group list-group-flush mt-2">
                            <div className="list-group-item py-3 d-flex align-items-center">
                              <div className="flex-fill">
                                <span className="d-block h6 text-sm font-semibold mb-1">{fileName}</span>
                                <span className="d-block text-xs text-muted">{fileSize}</span>
                              </div>
                              {isFileUploaded && ( // Mostrar el menú desplegable solo cuando el archivo esté cargado
                                <div className="ms-auto">
                                  <div className="dropdown">
                                    <a className="text-muted" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <i className="bi bi-three-dots-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <button className="dropdown-item" onClick={handleFileDelete}>Eliminar</button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card position-sticky top-24">
                    <div class="card-body pb-0">
                      <h6 class="mb-4">Dinero depositado</h6>
                      <div>
                        <div class="">
                          <div class="">
                            <div class="">
                              <div class="input-group position-relative">
                                <span class="input-group-text" id="">Valor</span>
                                <input
                                  className="form-control"
                                  placeholder="0.00"
                                  aria-label="0.00"
                                  aria-describedby=""
                                  defaultValue={value}
                                  onChange={handleValueChange}
                                />
                                <input
                                  className="form-control"
                                  placeholder="Moneda"
                                  aria-label="Moneda"
                                  aria-describedby=""
                                  defaultValue={currency}
                                  onChange={handleCurrencyChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {formErrors.value && (
                          <p style={{ fontSize: '12px', paddingTop: '10px', color: 'red' }}>{formErrors.value}</p>
                        )}
                        {formErrors.currency && (
                          <p style={{ fontSize: '12px', color: 'red' }}>{formErrors.currency}</p>
                        )}
                        <div class="d-flex gap-3 justify-content-end mt-4" style={{ fontSize: '10px' }}>
                          Monedas PEN / USD
                        </div>
                        <hr class="mt-4 mb-0" />
                        <div class="list-group list-group-flush">
                          <div class="list-group-item">
                            <div class="d-flex align-items-center">
                              <h6 class="text-sm text-muted font-semibold">Status</h6>
                            </div>
                            <div class="mt-3">
                              <span class="badge badge-sm bg-warning text-white">En progreso</span>
                            </div>
                          </div>
                          <div class="list-group-item">
                            <div class="d-flex align-items-center">
                              <h6 class="text-sm text-muted font-semibold">Prioridad</h6>
                            </div>
                            <div class="mt-3">
                              <span class="badge badge-sm bg-soft-success text-success">
                                <i class="bi bi-hand-thumbs-up-fill me-2"></i>Alta </span>
                            </div>
                          </div>
                          <div class="list-group-item">
                            <h6 class="text-sm text-muted font-semibold">Fecha realizada</h6>
                            <div className="mt-3">
                              <div className="datepicker d-flex gap-2 align-items-center">
                                <input
                                  type="date" // No es compatible con todos los navegadores, pero algunos lo admiten
                                  value={selectedDate}
                                  onChange={handleSelectedDateChange}
                                  className="form-control form-control-flush text-sm text-muted font-semibold flatpickr-input"
                                  placeholder="Select date"
                                />
                              </div>
                              {formErrors.selectedDate && (
                                <p style={{ fontSize: '12px', paddingTop: '10px', color: 'red' }}>{formErrors.selectedDate}</p>
                              )}
                            </div>
                          </div>
                          <div class="list-group-item">
                            <div>
                              <h6 class="text-sm text-muted font-semibold">Notificaciones</h6>
                              <button type="button" class="btn btn-neutral btn-sm w-full mt-3">
                                <i class="bi bi-bell me-2"></i>Activo </button>
                              <small class="d-block mt-2 text-muted">Las notificaciones de transacciones están activadas de manera predeterminada.</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </BodyAccount>
  )
}
