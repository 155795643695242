import React, { useEffect, useState } from 'react'
import { BodyAccount } from "../../_styled/dashboard-components";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { serviceRequest } from '../../../static/request/production.request';
import Navbar from '../../_layout/_navbar/NavbarComponent';
import DefaultHeader from '../../_layout/_headers/DefaultHeader';
import HederTitle from '../../_layout/_headers/HederTitle';
import { Redirect } from 'react-router-dom';
import LazyLoading from '../../../components/loading';

export default function TransferServicePage() {
    const [isLoading, setIsLoading] = useState(false);
    const [transferService, setTransferService] = useState([]);
    const userId = JSON.parse(localStorage.getItem("user"))?._id;

    useEffect(() => {
        const fetchTransferService = async () => {
            try {
                setIsLoading(true);

                const response = await serviceRequest.get(`/user/${userId}`);
                setTransferService(response.data);
            } catch (error) {
                setTransferService(error.response.data);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTransferService();
    }, [userId]);

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid"> <div className="border-bottom pt-6"> <HederTitle title='Transferencia de servicio' icon='📫' /> <ul className="nav nav-tabs overflow-x border-0"> <li className="nav-item"> <a href="#" className="nav-link active">Datos de transferencia</a> </li>  </ul> </div></div>
                    </header>
                    <main class="py-6 bg-surface-secondary">
                        {isLoading ? (
                            <LazyLoading /> // Renderizar el componente Loading mientras se carga la información
                        ) : (
                            <div class="container-fluid">
                                <div class="row">
                                    {Object.keys(transferService).length >= 1 && (
                                        <div class="col-xl-7 mx-auto">
                                            {transferService && (
                                                <>
                                                    <div class="card shadow border-0 mt-4 mb-10">
                                                        <div class="card-body">
                                                            <div class="d-flex align-items-center">
                                                                <div>
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="w-12 mx-auto">
                                                                            <img alt="..." className="w-full" src="https://mapps.click/static/images/Netflix_icon.svg" />
                                                                        </div>
                                                                        <div class="ms-6">
                                                                            <div class="dropdown">
                                                                                <h3>Visualizador de datos</h3>
                                                                                <p class="text-sm font-semibold text-muted"> Información general </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <form class="mb-6">
                                                        <div class="row g-5">
                                                            <div class="col-md-15">
                                                                <div>
                                                                    <label className="form-label" htmlFor="formInputExample"> Email address </label> {transferService.mappsUser ? (<div className="input-group position-relative">
                                                                        <CopyToClipboard text={transferService.mappsUser}>
                                                                            <button type="button" className="btn btn-sm btn-primary">
                                                                                <i className="bi bi-clipboard"></i>
                                                                            </button>
                                                                        </CopyToClipboard>
                                                                        <input type="text" className="form-control" style={{ textAlign: "center" }} aria-label="miremoslo.com" aria-describedby="" defaultValue={transferService.mappsUser} maxLength="0" />
                                                                    </div>) : (<div className="form-control" style={{ textAlign: 'center' }}>{transferService.message}</div>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div style={{ fontSize: '14px' }}> Para completar exitosamente tu solicitud, asegúrate de proporcionar los siguientes datos en tus servicios: </div>
                                                    <hr class="my-7" />
                                                    <div class="row g-6">
                                                        <div class="col-md-12">
                                                            <div class="card shadow border-0">
                                                                <div class="card-body d-flex align-items-center">
                                                                    <div>
                                                                        <h5 class=" mb-2 text-primary">Auto cancelación</h5>
                                                                        <p class="text-sm text-muted"> Si no se completa o no se recibe respuesta a esta solicitud en un plazo de 24 horas, será cancelada automáticamente. </p>
                                                                    </div>
                                                                    <div class="form-check form-switch" style={{ "margin-left": "15px" }}>
                                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </main>
                </div>
            </div>
        </BodyAccount>
    )
}
