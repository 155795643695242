import React from 'react';

import NetflixLogo from '../../../assents/logos/netflix.jpeg';
import StarPlusLogo from '../../../assents/logos/starplus.jpg';
import DirectvGoLogo from '../../../assents/logos/directvgo.png';
import BotLogo from '../../../assents/logos/bot.png';

export default function HederTitle(props) {
    const { title, icon } = props;
    const userId = JSON.parse(localStorage.getItem("user"))?._id;
    const userName = JSON.parse(localStorage.getItem("user"))?.username;

    return (
        <>
            <div className="row align-items-center">
                <div className="col-sm col-12">
                    <h1 className="h2 ls-tight">
                        <span className="d-inline-block me-3">{`${icon}`}</span>{`${title}`}
                    </h1>
                </div>
                <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                    <div className="hstack gap-2 justify-content-sm-end">
                        <a href="/services?name=play" className="btn btn-sm btn-neutral border-base">
                            <span className="pe-2">
                                <i className="bi bi-people-fill"></i>
                            </span>
                            <span>Mis servicios</span>
                        </a>
                        <a href="#addService" className="btn btn-sm btn-primary" data-bs-toggle="modal">
                            <span className="pe-2">
                                <i className="bi bi-plus-square-dotted"></i>
                            </span>
                            <span>Agregar servicios</span>
                        </a>
                        <button type="button" className="btn btn-sm btn-square btn-neutral rounded-circle d-xl-none" data-bs-toggle="offcanvas" data-bs-target="#responsiveOffcanvas" aria-controls="responsiveOffcanvas">
                            <i className="bi bi-three-dots"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                {/* TYPEFORM MODAL */}
                <div className="modal fade" id="addService" tabIndex="-1" aria-labelledby="addServiceLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content overflow-hidden">
                            <div className="modal-header pb-0 border-0">
                                <h1 className="modal-title h4" id="addServiceLabel">Agregar Servicios</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0">
                                <div className="px-6 py-5 border-bottom">
                                    <input type="text" className="form-control" placeholder="Buscar más servicios" aria-label="Search" disabled style={{ backgroundColor: 'white', opacity: '1' }} />
                                </div>
                                <div className="p-2">
                                    <div className="vstack">
                                        <div className="position-relative d-flex gap-3 p-4 rounded bg-light-hover">
                                            <div className="icon flex-none">
                                                <img src={NetflixLogo} className="w-10 h-10" alt="..." style={{ width: "100%", borderRadius: "25%" }} />
                                            </div>
                                            <div className="d-flex flex-fill">
                                                <div data-tf-popup="i5fdzIdu" data-tf-hide-headers data-tf-hidden={`name=${userName}, identifier=${userId}, subscription=Play`} data-tf-iframe-props="title=Miremoslo Play" data-tf-medium="snippet">
                                                    <a href="#play" className="stretched-link text-heading font-bold" data-bs-toggle="modal">Plataforma Play Única</a>
                                                    <span className="d-block text-muted text-sm">Solicitar servicio</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto font-bold text-primary" style={{ fontSize: '1.70rem' }}><i className="bi bi-bookmark-check-fill"></i></div>
                                        </div>
                                        <div className="position-relative d-flex gap-3 p-4 rounded bg-light-hover">
                                            <div className="icon flex-none">
                                                <img src={NetflixLogo} className="w-10 h-10" alt="..." style={{ width: "100%", borderRadius: "25%" }} />
                                            </div>
                                            <div className="d-flex flex-fill">
                                                <div data-tf-popup="MKNxc00X" data-tf-hide-headers data-tf-hidden={`name=${userName}, identifier=${userId}, subscription=Play`} data-tf-iframe-props="title=Miremoslo Play" data-tf-medium="snippet">
                                                    <a href="#play" className="stretched-link text-heading font-bold" data-bs-toggle="modal">Plataforma Play Mensual</a>
                                                    <span className="d-block text-muted text-sm">Solicitar servicio</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto font-bold text-primary" style={{ fontSize: '1.70rem' }}><i className="bi bi-bookmark-check-fill"></i></div>
                                        </div>
                                        <div className="position-relative d-flex gap-3 p-4 rounded bg-light-hover">
                                            <div className="icon flex-none">
                                                <img src={StarPlusLogo} className="w-10 h-10" alt="..." style={{ width: "100%", borderRadius: "25%" }} />
                                            </div>
                                            <div className="d-flex flex-fill">
                                                <div data-tf-popup="FxIfN47m" data-tf-hide-headers data-tf-hidden={`name=${userName}, identifier=${userId}, subscription=Plus`} data-tf-iframe-props="title=Miremoslo Play" data-tf-medium="snippet">
                                                    <a href="#plus" className="stretched-link text-heading font-bold" data-bs-toggle="modal">Plataforma Plus</a>
                                                    <span className="d-block text-muted text-sm">Solicitar servicio</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto font-bold text-primary" style={{ fontSize: '1.70rem' }}><i className="bi bi-bookmark-check-fill"></i></div>
                                        </div>
                                        <div className="position-relative d-flex gap-3 p-4 rounded bg-light-hover">
                                            <div className="icon flex-none">
                                                <img src={DirectvGoLogo} className="w-10 h-10" alt="..." style={{ width: "100%", borderRadius: "25%" }} />
                                            </div>
                                            <div className="d-flex flex-fill">
                                                <div data-tf-popup="l1YS7jcY" data-tf-hide-headers data-tf-hidden={`name=${userName}, identifier=${userId}, subscription=Go`} data-tf-iframe-props="title=Miremoslo Play" data-tf-medium="snippet">
                                                    <a href="#go" className="stretched-link text-heading font-bold" data-bs-toggle="modal">Plataforma Go</a>
                                                    <span className="d-block text-muted text-sm">Solicitar servicio</span>
                                                </div>
                                            </div>
                                            <div className="ms-auto font-bold text-primary" style={{ fontSize: '1.70rem' }}><i className="bi bi-bookmark-check-fill"></i></div>
                                        </div>
                                        <div className="position-relative d-flex gap-3 p-4 rounded bg-light-hover">
                                            <div className="icon flex-none">
                                                <img src={BotLogo} className="w-10 h-10" alt="..." style={{ width: "100%", borderRadius: "25%" }} />
                                            </div>
                                            <div className="d-flex flex-fill">
                                                <div data-tf-popup="tcf1vMMP" data-tf-hide-headers data-tf-hidden={`name=${userName}, identifier=${userId}, actionType=Support`} data-tf-iframe-props="title=Miremoslo Play" data-tf-medium="snippet">
                                                    <a href="#soporte" className="stretched-link text-heading font-bold" data-bs-toggle="modal">Soporte</a>
                                                    <span className="d-block text-muted text-sm">Redirigir a soporte</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-6 py-5 bg-light d-flex justify-content-center">
                                        <a href="/services?name=play" className="btn btn-sm btn-dark rounded-pill">
                                            <i className="bi bi-gear me-2"></i> Ir a mis Servicios
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
