import { BodyAccount } from "../../_styled/dashboard-components";
import Navbar from "../../_layout/_navbar/NavbarComponent";
import DefaultHeader from "../../_layout/_headers/DefaultHeader";
import styled from "styled-components";
import HederTitle from "../../_layout/_headers/HederTitle";
import { Redirect } from "react-router-dom";

const ButtonAccount = styled.form`
    all:unset;
    font-family:Helvetica,Arial,
    sans-serif;
    display:inline-block;
    max-width:100%;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    background-color:#0445AF;
    color:#FFFFFF;
    font-size:20px;
    border-radius:25px;
    padding:0 33px;
    font-weight:bold;
    height:50px;
    cursor:pointer;
    line-height:50px;
    text-align:center;
    margin:0;
    text-decoration:none;
`;

export default function SupportPage() {
    const userId = JSON.parse(localStorage.getItem("user"))?._id;
    const userName = JSON.parse(localStorage.getItem("user"))?.username;

    if (!userId && !userName) {
        return <Redirect replace to="/login" />;
    }

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid">
                            <div className="border-bottom pt-6">
                                <HederTitle title='Soporte' icon='🚀' />
                                <ul className="nav nav-tabs overflow-x border-0">
                                    <li className="nav-item">
                                        <a href="/home" className="nav-link">Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/history" className="nav-link">Historial</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/support" className="nav-link active">¿Necesitas ayuda?</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </header>
                    <main class="py-6 bg-surface-secondary">
                        <div class="container py-4 py-xl-5" style={{ "position": "static", "margin-top": "25px", "margin-bottom": "-10px" }}>
                            <div class="container py-4 py-xl-5" style={{ "position": "static", "margin-top": "0px", "margin-bottom": "-10px" }}>
                                <h2 class="text-uppercase fw-bold" style={{ "text-align": "center", "font-size": "36px", "font-weight": "700" }}>¿Tienes algún problema?</h2>
                            </div>
                            <p style={{ "text-align": "center" }}>Iniciemos esta conversación para encontrar la solución más óptima para ti.</p>
                            <div>
                                <p style={{ "textAlign": "center" }}> Ofrecemos el servicio de soporte las 24 horas, todos los días. </p>
                            </div>
                            <div style={{ "textAlign": "center", "padding-top": "20px" }}>
                                <ButtonAccount data-tf-popup="tcf1vMMP" data-tf-hide-headers data-tf-hidden={`name=${userName}, identifier=${userId}, actionType=Support`} data-tf-iframe-props="title=Soporte" data-tf-medium="snippet">Empezar</ButtonAccount>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </BodyAccount>
    )
}
