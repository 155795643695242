import React from 'react'
import { Redirect } from 'react-router-dom';
import { BodyAccount } from '../../_styled/dashboard-components'
import Navbar from '../../_layout/_navbar/NavbarComponent'
import DefaultHeader from '../../_layout/_headers/DefaultHeader'

export default function AnnouncementsPage() {
    const titleAnnouncements = JSON.parse(localStorage.getItem("announcements"))?.title;
    const contentAnnouncements = JSON.parse(localStorage.getItem("announcements"))?.content;
    const userId = JSON.parse(localStorage.getItem("user"))?._id;

    if (!userId && !titleAnnouncements && !contentAnnouncements) {
        return <Redirect replace to="/login" />;
    }

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid">
                            <div className="border-bottom pt-6">
                                <div className="row align-items-center">
                                    <div className="col-sm col-12">
                                        <h1 className="h2 ls-tight">
                                            <span className="d-inline-block me-3">😎</span>Anunciamientos
                                        </h1>
                                    </div>
                                    <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                                        <div className="hstack gap-2 justify-content-sm-end">
                                            <a href="/services?name=play" className="btn btn-sm btn-neutral border-base">
                                                <span className="pe-2">
                                                    <i className="bi bi-people-fill"></i>
                                                </span>
                                                <span>Mis servicios</span>
                                            </a>
                                            <a href="/home" className="btn btn-sm btn-primary">
                                                <span className="pe-2">
                                                    <i className="bi bi-plus-square-dotted"></i>
                                                </span>
                                                <span>Agregar servicios</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <ul className="nav nav-tabs overflow-x border-0">
                                    <li className="nav-item">
                                        <a type='button' className="nav-link active">Planes de precios</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </header>
                    <div class="flex-fill overflow-y-lg-auto scrollbar  rounded-top-4 rounded-top-start-lg-4 rounded-top-end-lg-0 shadow">
                        <main class="container-fluid px-3 py-5 p-lg-6 p-xxl-10">
                            <div class="alert bg-transparent text-primary border-primary" role="alert" style={{ marginBottom: '25px' }}>
                                <div class="d-flex">
                                    <div class="flex-none me-3">
                                        <div class="icon icon-shape text-base w-12 h-12 bg-soft-primary text-primary rounded-circle">
                                            <i class="bi bi-alarm text-primary"></i>                                       
                                             </div>
                                    </div>
                                    <div class="flex-fill">
                                        <div class="text-sm">
                                            <strong>{titleAnnouncements || 'Se produjo un error al obtener las últimas novedades y actualizaciones'}</strong>
                                        </div>
                                        <div>
                                            <p class="text-sm text-muted">{contentAnnouncements || 'Te recomendamos cerrar sesión en tu cuenta y esperar un tiempo antes de intentarlo nuevamente.'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-cols-1 row-cols-xl-3 justify-content-center g-6">
                                <div class="col">
                                    <div class="card card-pricing">
                                        <div class="px-10 pt-10 pb-7">
                                            <h4 class="ls-tight font-semibold mb-1">Plataforma Plus</h4>
                                            <div class="d-flex align-items-center my-5">
                                                <span class="d-block display-5 text-heading mb-0">$2.99</span>
                                                <span class="d-block h3 font-weight-normal text-muted align-self-end mb-3 ms-1">/ mes</span>
                                            </div>
                                            <p class="mb-4">Tus historias favoritas, todas en un mismo lugar.</p>
                                            <ul class="list-group list-group-flush text-sm mt-3">
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>18+ Locaciones</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>4+ Dispositivos simultaneos</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>Acceso total</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>Personalización</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>Soporte disponible</p>
                                                </li>
                                            </ul>
                                            <div class="mt-3">
                                                <a href="/home" class="btn btn-dark w-full rounded-pill">Solicitarlo</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card card-pricing bg-dark">
                                        <div class="px-10 pt-10 pb-7">
                                            <h4 class="ls-tight text-white font-semibold mb-1">Plataforma Play</h4>
                                            <div class="d-flex align-items-center my-5">
                                                <span class="d-block display-5 text-white mb-0">$4.50</span>
                                                <span class="d-block h3 font-weight-normal text-white text-opacity-75 align-self-end mb-3 ms-1">/ mes</span>
                                            </div>
                                            <p class="text-white text-opacity-75">Películas y series ilimitadas y mucho más.</p>
                                            <p class="text-white text-opacity-75 mb-4">Disfruta donde quieras y cuando quieras.</p>
                                            <ul class="list-group list-group-flush text-sm mt-3">
                                                <li class="list-group-item d-flex align-items-center py-3 text-white text-opacity-80 border-white border-opacity-20">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>10+ Locaciones</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3 text-white text-opacity-80 border-white border-opacity-20">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>4+ Dispositivos simultaneos</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3 text-white text-opacity-80 border-white border-opacity-20">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>Acceso total</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3 text-white text-opacity-80 border-white border-opacity-20">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>Personalización</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3 text-white text-opacity-80 border-white border-opacity-20">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>Soporte disponible</p>
                                                </li>
                                            </ul>
                                            <div class="mt-3">
                                                <a href="/home" class="btn btn-primary w-full rounded-pill">Solicitarlo</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card card-pricing">
                                        <div class="px-10 pt-10 pb-7">
                                            <h4 class="ls-tight text-heading font-semibold mb-1">Plataform Go</h4>
                                            <div class="d-flex align-items-center my-5">
                                                <span class="d-block display-5 text-heading mb-0">$2.99</span>
                                                <span class="d-block h3 font-weight-normal text-muted align-self-end mb-3 ms-1">/ mes</span>
                                            </div>
                                            <p class="mb-4">Disfruta lo mejor de fútbol y el entretenimiento.</p>
                                            <ul class="list-group list-group-flush text-sm mt-3">
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>6+ Locaciones</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>2+ Dispositivos Simultaneos</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>Acceso total</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>Personalización</p>
                                                </li>
                                                <li class="list-group-item d-flex align-items-center py-3">
                                                    <div class="icon icon-xs text-base icon-shape rounded-circle bg-opacity-30 bg-success text-success me-3">
                                                        <i class="bi bi-check"></i>
                                                    </div>
                                                    <p>Soporte disponible</p>
                                                </li>
                                            </ul>
                                            <div class="mt-3">
                                                <a href="/home" class="btn btn-dark w-full rounded-pill">Solicitarlo</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-20">
                                <div class="row mb-20">
                                    <div class="col-lg-7">
                                        <h1 class="ls-tight font-bolder mb-4">¿Necesitas ayuda adicional?</h1>
                                        <p class="lead">Si tienes alguna pregunta o duda sobre nuestros servicios, no dudes en contactarnos.</p>
                                    </div>
                                </div>
                                {/* <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                    <div class="col">
                                        <div class="vstack gap-10">
                                            <div class="">
                                                <h4 class="mb-4">Is there a free version?</h4>
                                                <p class="text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                            <div class="">
                                                <h4 class="mb-4">Is there a free version?</h4>
                                                <p class="text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                            <div class="">
                                                <h4 class="mb-4">Is there a free version?</h4>
                                                <p class="text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="vstack gap-10">
                                            <div class="">
                                                <h4 class="mb-4">Is there a free version?</h4>
                                                <p class="text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                            <div class="">
                                                <h4 class="mb-4">Is there a free version?</h4>
                                                <p class="text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                            <div class="">
                                                <h4 class="mb-4">Is there a free version?</h4>
                                                <p class="text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="vstack gap-10">
                                            <div class="">
                                                <h4 class="mb-4">Is there a free version?</h4>
                                                <p class="text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                            <div class="">
                                                <h4 class="mb-4">Is there a free version?</h4>
                                                <p class="text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                            <div class="">
                                                <h4 class="mb-4">Is there a free version?</h4>
                                                <p class="text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </BodyAccount>
    )
}
