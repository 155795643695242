import React, { useState } from 'react'
import { publicRequest } from '../../../static/request/production.request';


export default function AuthPasswordHelpPage() {

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setEmailError(emailRegex.test(value) ? '' : 'Por favor, ingresa un correo electrónico válido.');
  }

  const handleClick = async (e) => {
    e.preventDefault();

    if (email === '' || emailError !== '') {
      setErrorMessage('Por favor, ingresa un correo electrónico válido.');
      return;
    }

    setSuccessMessage('');
    setErrorMessage('');

    try {
      const res = await publicRequest.post(`/auth/forgot-password`, { email });
      setSuccessMessage(res.data.message);
    } catch (error) {
      if (error.response?.status === 400 && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Ocurrió un error al restablecer la contraseña. Por favor, inténtalo de nuevo más tarde.');
      }
    }
  };

  return (
    <div class="px-5 h-screen bg-surface-secondary d-flex flex-column justify-content-center">
      <div class="d-flex justify-content-center">
        <div class="col-lg-5 col-xl-4 p-12 p-xl-20 position-fixed start-0 top-0 h-screen overflow-y-hidden bg-primary d-none d-lg-flex flex-column">
          <a class="d-block" href="/login">
            <img src="https://mapps.click/static/images/MSL-N.png" class="h-10" alt="..." />
          </a>
          <div class="mt-32 mb-20">
            <h1 class="ls-tight font-bolder display-6 text-white mb-5">Construyamos algo asombroso hoy.</h1>
            <p class="text-white text-opacity-80">Mapps revoluciona el entretenimiento en streaming al ofrecer una experiencia de calidad a precios asequibles.
            </p>
          </div>
          <div class="w-56 h-56 bg-orange-500 rounded-circle position-absolute bottom-0 end-20 transform translate-y-1/3"></div>
        </div>
        <div class="col-12 col-md-9 col-lg-7 offset-lg-5 h-screen min-h-lg-screen d-flex flex-column justify-content-center py-lg-16 px-lg-20 position-relative">
          <div class="row">
            <div class="col-lg-10 col-md-9 col-xl-6 mx-auto ms-xl-0">
              <div class="mb-8">
                <h3 class="display-5">🔐</h3>
                <h1 class="h2 ls-tight font-bolder mt-6">Password Reset.</h1>
                <p class="mt-2">Ingresa tu correo para recuperar la contraseña.</p>
              </div>
              <form>
                <div class="mb-5">
                  <label class="form-label" for="email">Email address</label>
                  <input type="email" class="form-control" id="email" value={email} onChange={handleEmailChange} />
                  {emailError && <p style={{ paddingTop: '10px', fontSize: '12px' }}>{emailError}</p>} {/* Mostrar el mensaje de error si existe */}
                </div>
                <div>
                  <button
                    className="btn btn-primary w-full"
                    onClick={handleClick}
                  >
                    Enviar
                  </button>
                </div>
              </form>
              <div style={{ paddingTop: '25px' }}>
                {successMessage && <p className='text-success' style={{ fontSize: '12px' }}>{successMessage}</p>}{/* Mostrar el mensaje de éxito si existe */}
                {errorMessage && <p className='text-danger' style={{ fontSize: '12px' }}>{errorMessage}</p>} {/* Mostrar el mensaje de error si existe */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
