import { BodyAccount } from "../../_styled/dashboard-components";
import { Redirect } from "react-router-dom";
import Navbar from "../../_layout/_navbar/NavbarComponent";
import DefaultHeader from "../../_layout/_headers/DefaultHeader";


export default function ChatPage() {
    const userId = JSON.parse(localStorage.getItem("user"))?._id;

    if (!userId) {
        return <Redirect replace to="/login" />;
    }

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid"> <div className="border-bottom pt-6"> <div className="row align-items-center"> <div className="col-sm col-12"> <h1 className="h2 ls-tight"> <span className="d-inline-block me-3">💬</span>Chat </h1> </div> <div className="col-sm-auto col-12 mt-4 mt-sm-0"> <div className="hstack gap-2 justify-content-sm-end"> <a href="/services?name=play&service_type=monthly_services" className="btn btn-sm btn-neutral border-base"> <span className="pe-2"> <i className="bi bi-people-fill"></i> </span> <span>Administrar servicios</span> </a> <a href="#offcanvasCreate" className="btn btn-sm btn-primary" data-bs-toggle="offcanvas"> <span className="pe-2"> <i className="bi bi-plus-square-dotted"></i> </span> <span>Agregar servicios</span> </a> </div> </div> </div> <ul className="nav nav-tabs overflow-x border-0"> <li className="nav-item"> <a href="#" className="nav-link active">Coversaciones</a> </li> </ul> </div> </div>
                    </header>
                    <main class="py-6 bg-surface-secondary">
                        <div class="loader" style={{ "padding-top": "7.5rem" }}> <div class="container py-4 py-xl-5" style={{ "position": "static", "margin-top": "0px", "margin-bottom": "-10px" }}> <h2 class="text-uppercase fw-bold" style={{ "text-align": "center", "font-size": "36px", "font-weight": "700" }}>MáS CON <span style={{ "color": "rgb(26, 56, 214)" }}>MiREMOSLO</span>. LA MEJOR FORMA DE COMPARTIR</h2> <h5 class="text-uppercase fw-bold" style={{ "text-align": "center", "font-weight": "700" }}>No te pierdas ningún capítulo de estreno, series, y películas DE TU ELECCIÓN.</h5> </div> <p style={{ "text-align": "center" }}>Lo sentimos, estamos trabajando aún en esta opción.</p>
                        </div>
                    </main>
                </div>
            </div>
        </BodyAccount>
    )
}
