import "../../../styles/styles.css"
import { logout } from "../../../static/redux/_routes";

export default function Navbar() {
    const userProfile = JSON.parse(localStorage.getItem("user"))?.profile;

    const handleClick = (e) => {
        e.preventDefault();
        logout();
    };

    return (
        <nav class="flex-none navbar navbar-vertical navbar-expand-lg navbar-light bg-white show h-lg-screen px-0 py-5 border-bottom border-bottom-lg-0 border-end-lg scrollbar">
            <div className="container-fluid px-3 px-md-4 px-lg-6">
                <button class="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <a class="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" href="/#">
                    <img style={{ "height": "36px" }} src="https://mapps.click/static/images/Logo_Miremoslo_Net.jpg" alt="..." />
                </a>
                <div class="navbar-user d-lg-none">
                    <div class="dropdown">
                        <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="avatar-parent-child">
                                <img alt="..." src={`${userProfile}`} class="avatar avatar- rounded-circle" />
                                <span class="avatar-child avatar-badge bg-success"></span>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                            <a className="dropdown-item" href="/home">
                                <i className="bi bi-house me-3"></i>Inicio </a>
                            <a className="dropdown-item" href="/services?name=play">
                                <i className="bi bi-back me-3"></i>Mis servicios </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/payment">
                                <i className="bi bi-wallet2 me-3"></i>Transacciones </a>
                            <a className="dropdown-item" href="/chat">
                                <i className="bi bi-chat me-3"></i>Mensajes </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/transfer-service">
                                <i className="bi bi-code-square me-3"></i>Transferencia </a>
                            <a className="dropdown-item" onClick={handleClick}>
                                <i className="bi bi-box-arrow-left me-3"></i>Cerrar Sesión </a>
                        </div>
                    </div>
                </div>
                <div class="navbar-collapse collapse" id="sidebarCollapse">
                    <ul class="navbar-nav">
                        <li class="nav-item my-1">
                            <a class="nav-link" href="/home" role="button" aria-expanded="true" aria-controls="sidebar-projects">
                                <i class="bi bi-house"></i> Dashboard </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#sidebar-integrations" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebar-integrations">
                                <i class="bi bi-back"></i> Mis servicios </a>
                            <div class="collapse" id="sidebar-integrations">
                                <ul class="nav nav-sm flex-column">
                                    <li class="nav-item">
                                        <a href="/services?name=play" class="nav-link" style={{ marginLeft: '3px' }}> Plataforma Play</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="/services?name=plus" class="nav-link" style={{ marginLeft: '3px' }}> Plataforma Plus</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="/services?name=go" class="nav-link" style={{ marginLeft: '3px' }}> Plataforma Go</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="/payment" role="button" aria-expanded="true" aria-controls="sidebar-projects">
                                <i class="bi bi-wallet2"></i> Transacciones </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="/chat" role="button" aria-expanded="true" aria-controls="sidebar-projects">
                                <i class="bi bi-chat"></i> Mensajes </a>
                        </li>
                    </ul>
                    <hr class="navbar-divider my-4 opacity-70" />
                    <ul class="navbar-nav">
                        <li>
                            <span class="nav-link text-xs font-semibold text-uppercase text-muted ls-wide">Recursos</span>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link py-2" href="/transfer-service">
                                <i class="bi bi-code-square"></i> Transferencia </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link py-2 d-flex align-items-center">
                                <i class="bi bi-journals"></i>
                                <span>Changelog</span>
                                <span class="badge badge-sm bg-soft-success text-success rounded-pill ms-auto">v1.0.2</span>
                            </a>
                        </li>
                    </ul>
                    <div class="mt-auto"></div>
                    <div class="border-0 mt-5 mb-3 position-relative">
                        <div class="dropup w-full">
                            <a type="button" href="/announcements" class="btn-primary d-flex w-full py-3 ps-3 pe-4 align-items-center shadow shadow-3-hover rounded-3">
                                <span class="me-3">
                                    <i class="bi bi-emoji-sunglasses-fill text-white text-xl"></i>
                                </span>
                                <span class="flex-fill text-start text-sm font-semibold"> Novedades </span>
                                <span>
                                    <i class="bi bi-arrow-right ms-2"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
