import React, { useState } from 'react'
import NetflixLogo from '../assents/logos/netflix.jpeg';
import StarPlusLogo from '../assents/logos/starplus.jpg';
import DirectvGoLogo from '../assents/logos/directvgo.png';

function SubscriptionsSection(props) {
  const { subscriptions } = props;

  const itemsPerPage = 3; // Número de objetos por página
  const [currentPage, setCurrentPage] = useState(1); // Página actual

  // Calcular el índice inicial y final de los objetos a mostrar en la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Obtener los objetos de la página actual
  const currentSubscriptions = subscriptions.slice(startIndex, endIndex);

  // Función para cambiar a la página anterior
  const goToPreviousPage = () => {
      setCurrentPage((prevPage) => prevPage - 1);
  };

  // Función para cambiar a la página siguiente
  const goToNextPage = () => {
      setCurrentPage((prevPage) => prevPage + 1);
  };  

  function getProgressBarClass(status) {
      switch (status) {
          case 'Activo':
              return 'bg-success';
          case 'Pendiente de renovación':
              return 'bg-primary';
          case 'Retrasada':
              return 'bg-warning';
          case 'Caducada':
          case 'Cancelado':
              return 'bg-danger';
          default:
              return '';
      }
  }

  return (
      <div>
          {currentSubscriptions.map((subscription) => (
              // Renderizar los objetos de la página actual
              <div className="d-flex bg-light gap-3 rounded-3 p-4" key={subscription._id}>

                  <div class="w-8 h-8 flex-none">
                      {subscription.subscription.name === 'Play' && (
                          <img alt="..." src={NetflixLogo} style={{ borderRadius: "25%" }} />
                      )}
                      {subscription.subscription.name === 'Plus' && (
                          <img alt="..." src={StarPlusLogo} style={{ borderRadius: "25%" }} />
                      )}
                      {subscription.subscription.name === 'Go' && (
                          <img alt="..." src={DirectvGoLogo} style={{ borderRadius: "25%" }} />
                      )}
                  </div>

                  <div class="vstack gap-2">
                      <div class="d-flex mb-1"> <div class=""> <span class="d-block text-heading text-sm font-semibold">{`Plataforma ${subscription.subscription.name}`}</span> <span class="d-block text-muted text-xs">{`${subscription.subscription.renewalDate}`}</span> </div> <div class="ms-auto d-block text-heading text-sm font-semibold">{`${subscription.subscription.price?.amount}`} {`${subscription.subscription.price?.currency}`}</div> </div> <div className="progress"> <div className={`ress-bar ${getProgressBarClass(subscription.subscription.status)}`} role="progressbar" aria-label="Basic example" style={{ width: "100%" }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div> </div> <a href={`/services/${subscription._id}`}> <span class="d-block text-muted text-xs" style={{ textAlign: 'center' }}>{`${subscription.credentials?.group.mappsId}`}</span> </a>
                  </div>
              </div>
          ))}

          {/* Botones de navegación */}
          {subscriptions.length > 3 && (
              <div class="d-flex gap-2 scrollable-x py-3 px-6">
                  <button type="button" class="btn btn-sm btn-neutral rounded-pill px-2 py-1 text-xs shadow-none" disabled={currentPage === 1} onClick={goToPreviousPage}>
                      <i class="bi bi-arrow-left-circle-fill opacity-70 me-1"></i>Anterior </button>
                  <button type="button" class="btn btn-sm btn-neutral rounded-pill px-2 py-1 text-xs shadow-none" disabled={endIndex >= subscriptions.length} onClick={goToNextPage}>
                      <i class="bi bi-arrow-right-circle-fill opacity-70 me-1"></i>Siguiente </button>
              </div>
          )}
      </div>
  );
}

export default SubscriptionsSection;