import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import AuthPage from "../pages/presentation/auth/AuthPage";
import AuthPasswordHelpPage from "../pages/presentation/auth/AuthPasswordHelpPage";
import AuthPasswordResetPage from "../pages/presentation/auth/AuthPasswordResetPage";
import DashboardPage from "../pages/presentation/dashboard/DashboardPage";
import HistoryPage from "../pages/presentation/history/HistoryPage";
import ServicesPage from "../pages/presentation/services/ServicesPage";
import SubscriptionPage from "../pages/presentation/services/subscription/SubscriptionsPage";
import PurchasesPage from "../pages/presentation/services/PurchasesPage";
import TokensPage from "../pages/presentation/services/TokensPage";
import ChatPage from "../pages/presentation/chat/ChatPage";
import PaymentPage from "../pages/presentation/payment/PaymentPage";
import TransferServicePage from "../pages/presentation/transfer/TransferServicePage";
import SupportPage from "../pages/presentation/support/SupportPage";
import AnnouncementsPage from "../pages/presentation/announcements/AnnouncementsPage";


const App = () => {

  const user = useSelector((state) => state.user.currentUser);

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/home" component={DashboardPage} isAuthenticated={user} />

        {/* Resto de las rutas */}
        <PrivateRoute path="/history" component={HistoryPage} isAuthenticated={user} />
        <PrivateRoute path="/announcements" component={AnnouncementsPage} isAuthenticated={user} />
        <PrivateRoute path="/services/:id" component={SubscriptionPage} isAuthenticated={user} />
        <PrivateRoute path="/services" component={ServicesPage} isAuthenticated={user} />
        <PrivateRoute path="/tokens" component={TokensPage} isAuthenticated={user} />
        <PrivateRoute path="/purchases" component={PurchasesPage} isAuthenticated={user} />
        <PrivateRoute path="/payment" component={PaymentPage} isAuthenticated={user} />
        <PrivateRoute path="/transfer-service" component={TransferServicePage} isAuthenticated={user} />
        <PrivateRoute path="/chat" component={ChatPage} isAuthenticated={user} />
        <PrivateRoute path="/support" component={SupportPage} isAuthenticated={user} />


        {/* Rutas de autenticación */}
        <Route exact path="/login" render={() => (user ? <Redirect to="/home" /> : <AuthPage />)} />
        <Route exact path="/forgot-password" render={() => (user ? <Redirect to="/forgot-password" /> : <AuthPasswordHelpPage />)} />
        <Route exact path="/password-reset" render={() => (user ? <Redirect to="/password-reset" /> : <AuthPasswordResetPage />)} />

        {/* Ruta por defecto */}
        <Redirect to="/home" />
      </Switch>
    </Router>
  );
};

// Componente para rutas protegidas
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default App;
