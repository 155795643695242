import axios from "axios";

// Base URL for API requests
const PROD_URL = "https://gateway.mapps.live/v1";

// Get access token from localStorage
const getToken = () => {
  const accessToken = JSON.parse(localStorage.getItem("user"))?.accessToken;
  return accessToken;
};

// Create an axios instance for public requests
const publicRequest = axios.create({
  baseURL: PROD_URL,
});


//HOME SECTION
const statsRequest = axios.create({
  baseURL: PROD_URL + '/stats',
  headers: { Authorization: `${getToken()}` }
});

const historiesRequest = axios.create({
  baseURL: PROD_URL + '/history',
  headers: { Authorization: `${getToken()}` }
});

const announcementsRequest = axios.create({
  baseURL: PROD_URL + '/announcements',
  headers: { Authorization: `${getToken()}` }
});

//SUBSCRIPTIONS SECTION
const subscriptionsRequest = axios.create({
  baseURL: PROD_URL + '/subscriptions',
  headers: { Authorization: `${getToken()}` }
});

const emailsRequest = axios.create({
  baseURL: PROD_URL + '/emails',
  headers: { Authorization: `${getToken()}` }
});

const tokensRequest = axios.create({
  baseURL: PROD_URL + '/tokens',
  headers: { Authorization: `${getToken()}` }
});

const purchasesRequest = axios.create({
  baseURL: PROD_URL + '/purchases',
  headers: { Authorization: `${getToken()}` }
});

// PAYMENTS SECTION
const paymentsRequest = axios.create({
  baseURL: PROD_URL + '/payments',
  headers: { Authorization: `${getToken()}` }
});

//SERVICES SECTION
const serviceRequest = axios.create({
  baseURL: PROD_URL + '/services/transfer-service',
  headers: { Authorization: `${getToken()}` }
});



export {
  publicRequest,
  announcementsRequest,
  serviceRequest,
  subscriptionsRequest,
  emailsRequest,
  historiesRequest,
  tokensRequest,
  paymentsRequest,
  statsRequest,
  purchasesRequest,
  getToken
};

