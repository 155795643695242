import React, { useState, useEffect } from "react";
import {
    getToken,
    historiesRequest,
    statsRequest,
    subscriptionsRequest
} from '../../../static/request/production.request';
import { BodyAccount } from "../../_styled/dashboard-components";
import Navbar from '../../_layout/_navbar/NavbarComponent';
import HederTitle from "../../_layout/_headers/HederTitle";
import DefaultHeader from '../../_layout/_headers/DefaultHeader';
import SubscriptionsSection from "../../../components/SubscriptionsSection";
import LazyLoading from "../../../components/loading";


export default function DashboardPage() {
    const [staticsData, setStaticsData] = useState([]);
    const [historiesData, setHistoriesData] = useState([]);
    const [subscriptionRenewaslData, setSubscriptionRenewalsData] = useState([]);
    const userId = JSON.parse(localStorage.getItem("user"))?._id;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [statsResponse, historiesResponse, subscriptionRenewalsResponse] = await Promise.all([
                    statsRequest.get(`/user/${userId}`, { headers: { Authorization: getToken() } }),
                    historiesRequest.get(`/user/${userId}`, { headers: { Authorization: getToken() } }),
                    subscriptionsRequest.get(`/user/${userId}/renewals`, { headers: { Authorization: getToken() } }),
                ]);

                setStaticsData(statsResponse.data);
                setHistoriesData(historiesResponse.data.docs);
                setSubscriptionRenewalsData(subscriptionRenewalsResponse.data.docs);
            } catch (error) {
                console.log('Error al obtener datos del usuario:', error);
            }
        };

        if (userId) {
            fetchData();
        }
    }, [userId]);

    return (
        <BodyAccount>
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="flex-grow-1 h-screen overflow-y-lg-auto">
                    <DefaultHeader />
                    <header>
                        <div className="container-fluid">
                            <div className="border-bottom pt-6"> <HederTitle title='Dashboard' icon='😎' /> <ul className="nav nav-tabs overflow-x border-0"> <li className="nav-item"> <a href="/home" className="nav-link active">Dashboard</a> </li> <li className="nav-item"> <a href="/history" className="nav-link">Historial</a> </li> <li className="nav-item"> <a href="/support" className="nav-link">¿Necesitas ayuda?</a> </li> </ul>
                            </div>
                        </div>
                    </header>
                    <main className="py-6 bg-surface-secondary">
                        <div className="container-fluid">
                            {staticsData && historiesData && Object.keys(staticsData).length >= 1 ? (
                                <div class="row g-3 g-lg-6">
                                    <div class="col-xl-8">
                                        <div class="vstack gap-3 gap-sm-6">
                                            <div class="row g-3">
                                                <div class="col-sm col-6">
                                                    <div class="card bg-success bg-opacity-10 border-success border-opacity-40">
                                                        <div class="card-body p-4">
                                                            <div class="d-flex align-items-center gap-2">
                                                                <i class="bi bi-receipt-cutoff w-4 flex-none text-success"></i>
                                                                <h6>Gastos</h6>
                                                            </div>
                                                            <div class="text-xs mt-4">Gastos mensuales</div>
                                                            <div class="text-sm font-semibold">{`${staticsData.expenseStats?.monthlyExpenses} ${staticsData.user_identifier?.currency}`}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm col-6">
                                                    <div class="card border-primary-hover">
                                                        <div class="card-body p-4">
                                                            <div class="d-flex align-items-center gap-2">
                                                                <i class="bi bi-app-indicator w-4 flex-none text-dark"></i>
                                                                <a class="h6 stretched-link">Servicios</a>
                                                            </div>
                                                            <div class="text-xs mt-4">Servicios totales</div>
                                                            <div class="d-flex align-items-center gap-2 mt-1 text-xs">
                                                                <span class="badge badge-xs rounded-pill text-bg-success">
                                                                    <i class="bi bi-arrow-up-right"></i>
                                                                </span>
                                                                <span>{`+${staticsData.serviceStats?.totalServices} este año`}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm col-6">
                                                    <div class="card border-primary-hover">
                                                        <div class="card-body p-4">
                                                            <div class="d-flex align-items-center gap-2">
                                                                <i class="bi bi-cart-plus w-4 flex-none text-warning"></i>
                                                                <a class="h6 stretched-link">Compras</a>
                                                            </div>
                                                            <div class="text-xs mt-4">Compras extras</div>
                                                            <div class="d-flex align-items-center gap-2 mt-1 text-xs">
                                                                <span class="badge badge-xs rounded-pill text-bg-success">
                                                                    <i class="bi bi-arrow-up-right"></i>
                                                                </span>
                                                                <span>{`+${staticsData?.purchaseStats} registradas`}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm col-6">
                                                    <div class="card border-primary-hover">
                                                        <div class="card-body p-4">
                                                            <div class="d-flex align-items-center gap-2">
                                                                <i class="bi bi-cash-coin w-4 flex-none text-black"></i>
                                                                <a class="h6 stretched-link">Transacciones</a>
                                                            </div>
                                                            <div class="text-xs mt-4">Pagos pendientes</div>
                                                            <div class="d-flex align-items-center gap-2 mt-1 text-xs">
                                                                <span class="badge badge-xs rounded-pill text-bg-danger">
                                                                    <i class="bi bi-arrow-up-right"></i>
                                                                </span>
                                                                <span>{`+${staticsData?.transactionStats} en marcha`}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-1 d-none d-sm-block">
                                                    <div class="card h-sm-full d-flex flex-column align-items-center justify-content-center py-4 bg-light bg-opacity-80 bg-opacity-100-hover">
                                                        <a href="#addService" class="stretched-link text-dark" data-bs-toggle="modal">
                                                            <i class="bi bi-plus-lg"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* HISTORY TRANSACTION SECTION */}
                                            <div class="card">
                                                <div class="card-header d-flex align-items-center border-bottom">
                                                    <h5 class="me-auto">Historial de acciones</h5>
                                                    <div class="hstack align-items-center">
                                                        <a href="/history" class="text-muted">
                                                            <i class="bi bi-list"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table table-hover table-nowrap">
                                                        <thead class="table-light">
                                                            <tr>
                                                                <th scope="col">Information</th>
                                                                <th scope="col"></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {historiesData.map((history) => (
                                                                <tr>
                                                                    <td>
                                                                        <div class="d-flex align-items-center gap-3">
                                                                            <div class="icon icon-shape rounded-circle icon-sm flex-none w-10 h-10 text-sm bg-primary bg-opacity-20 text-primary">
                                                                                <i class="bi bi-send-fill"></i>
                                                                            </div>
                                                                            <div class="">
                                                                                <span className="d-block text-heading text-sm font-semibold">
                                                                                    {
                                                                                        history.category === 'Subscriptions' ? 'Suscripciones'
                                                                                            : history.category === 'Services' ? 'Servicios'
                                                                                                : history.category === 'Purchases' ? 'Compras'
                                                                                                    : history.category === 'Expenses' ? 'Gastos'
                                                                                                        : history.category === 'Payments' ? 'Transacciones'
                                                                                                            : history.category
                                                                                    }
                                                                                </span>
                                                                                <span class="d-block d-sm-block text-muted text-xs">{`${history.date}`}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ margin: '0px 20px 0px 20px', textAlign: 'center', fontSize: '13px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={history.message} >
                                                                        {history.message.length > 52 ? `${history.message.substring(0, 52)}...` : history.message}
                                                                    </td>
                                                                    <td>
                                                                        <div className="text-end">
                                                                            <span
                                                                                className={`d-block badge bg-light rounded-pill ${history.status === 'Success' ? 'text-success'
                                                                                    : history.status === 'Warning' ? 'text-warning'
                                                                                        : history.status === 'Failure' ? 'text-danger'
                                                                                            : ''}`
                                                                                }
                                                                            > {(() => { switch (history.status) { case 'Success': return (<div> <i className="bi bi-check-circle me-1"></i> <span>Éxito</span> </div>); case 'Warning': return (<div> <i className="bi bi-exclamation-circle me-1"></i> <span>Aviso</span> </div>); case 'Failure': return (<div> <i className="bi bi-x-circle me-1"></i> <span>Error</span> </div>); default: return null; } })()} </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="card-footer text-start text-muted" style={{ fontSize: '12px', margin: '5px 0px 5px 0px', textAlign: 'center' }}> Mostrando 7 artículos de los últimos resultados encontrados </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4">
                                        <div class="offcanvas-xl m-xl-0 rounded-sm-4 offcanvas-end rounded-xl-0 overflow-hidden m-sm-4" tabindex="-1" id="responsiveOffcanvas" aria-labelledby="responsiveOffcanvasLabel">
                                            <div class="offcanvas-header rounded-top-4 bg-light">
                                                <h5 class="offcanvas-title" id="sresponsiveOffcanvasLabel">Quick Stats</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#responsiveOffcanvas" aria-label="Close"></button>
                                            </div>
                                            <div class="offcanvas-body d-flex flex-column p-3 p-sm-6 p-xl-0 gap-3 gap-xl-6">
                                                <div class="vstack gap-6 gap-xl-6">
                                                    <>
                                                        {/* USER BALANCE SECTION */}
                                                        <div class="card border-0 border-xl">
                                                            <div class="card-body d-flex flex-column p-0 p-xl-6">
                                                                <div class="d-flex justify-content-between align-items-center mb-3">
                                                                    <div> <h5>Balance</h5> </div> <div> <span class="text-heading font-bold"> <i class="bi bi-arrow-up me-2"></i> General </span> </div>
                                                                </div>

                                                                {/* <div class="text-2xl font-bolder text-heading ls-tight">23.863,21 USDT</div> */}
                                                                <div class="text-2xl font-bolder text-heading ls-tight">{`${staticsData.user_identifier?.credit} ${staticsData.user_identifier?.currency}`}</div>

                                                                <div class="d-flex align-items-center justify-content-between mt-8">
                                                                    <div class=""> <div class="d-flex gap-3 align-items-center"> <div class="icon icon-sm icon-shape text-sm rounded-circle bg-dark text-success"> <i class="bi bi-arrow-down"></i> </div> <span class="h6 font-semibold text-muted">Ingresos</span> </div> {/* <div class="font-bold text-heading mt-3">$23.863,21 USD</div> */} <div class="font-bold text-heading mt-3">{`${staticsData?.incomeStats} ${staticsData.user_identifier?.currency}`}</div> </div>

                                                                    <span class="vr bg-dark bg-opacity-10"></span>

                                                                    <div class=""> <div class="d-flex gap-3 align-items-center"> <div class="icon icon-sm icon-shape text-sm rounded-circle bg-dark text-danger"> <i class="bi bi-arrow-up"></i> </div> <span class="h6 font-semibold text-muted">Gatos</span> </div> {/* <div class="font-bold text-heading mt-3">$5.678,45 USD</div> */} <div class="font-bold text-heading mt-3">{`${staticsData.expenseStats?.totalExpenses} ${staticsData.user_identifier?.currency}`}</div> </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr class="my-0 d-xl-none" />

                                                        {/* RENEW TODAY SUBSCRIPTION SECTION */}
                                                        <div class="card border-0 border-xl">
                                                            <div class="card-body p-0 p-xl-6">
                                                                <div class="d-flex justify-content-between align-items-center mb-5">
                                                                    <div> <h5>Renuevan Pronto</h5> </div>
                                                                </div>
                                                                {Object.keys(subscriptionRenewaslData).length >= 1 ? (
                                                                    <div class="vstack gap-3">
                                                                        {/* {subscriptions.map((subscription) => ( */}
                                                                        <SubscriptionsSection subscriptions={subscriptionRenewaslData} />
                                                                        {/* ))} */}
                                                                    </div>
                                                                ) : (
                                                                    <p className="text-muted">No hay suscripciones próximas para renovar</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (<LazyLoading />)}
                        </div>
                    </main>
                </div>
            </div >
        </BodyAccount >
    );
}