
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    BodyLogin,
    ContainerLogin,
    LoginLayaout,
    LoginHeader,
    LogoButton,
    SpaceForLogin,
    ContentSpaceForLogin,
    AuthLayout,
    AuthForm,
    TitleHeading,
    InputContainer,
    FormInput,
    Button,
    ForgotPassword,
    ActionLink,
    TextRegister,
    Footer,
    FooterText,
    TextError
} from "../../_styled/auth-components";
import { login } from "../../../static/redux/_routes"
import { resetError, setError } from '../../../static/redux/userRedux';
import { useHistory } from 'react-router-dom';


export default function AuthPage() {

    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorPrevent, setErrorPrevent] = useState("");
    const { isFetching, error } = useSelector((state) => state.user);

    useEffect(() => {
        const handleBeforeUnload = () => {
            dispatch(resetError());
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);


    const handleClick = async (e) => {
        e.preventDefault();

        if (email !== "" && password !== "") {
            try {
                setErrorPrevent("");
                await login(dispatch, { email, password });

                history.push("/home"); // Cambia "/" por la ruta de tu página de inicio
                window.location.reload();
            } catch (error) {
                dispatch(setError(error));
                setTimeout(() => {
                    dispatch(resetError());
                }, 20000);
            }
        } else {
            setErrorPrevent("Por favor complete todos los campos Email y Password");
            setTimeout(() => {
                setErrorPrevent("");
            }, 1000);
        }
    };

    return (
        <BodyLogin>
            <ContainerLogin>
                <LoginLayaout>
                    <LoginHeader>
                        <LogoButton>
                            <img src="https://mapps.click/static/images/MSL-N.png" alt="Mapps Logo" id="LogoImage" />
                        </LogoButton>
                    </LoginHeader>
                    <SpaceForLogin>
                        <ContentSpaceForLogin>
                            <AuthLayout>
                                <AuthForm>
                                    <TitleHeading>Acceder</TitleHeading>

                                    <InputContainer className="mb-5">
                                        <FormInput
                                            type="email"
                                            id="email"
                                            className="form-control"
                                            autoComplete="current-email"
                                            placeholder="Email o nombre de usuario"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </InputContainer>
                                    <InputContainer className="mb-5">
                                        <FormInput
                                            type="password"
                                            id="password"
                                            placeholder="Password"
                                            className="form-control"
                                            autoComplete="current-password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </InputContainer>
                                    <Button onClick={handleClick} disabled={isFetching}>
                                        <span className="CallToAction">Acceder</span>
                                    </Button>
                                </AuthForm>
                                <ForgotPassword>
                                    <ActionLink href="/forgot-password" data-t="login-forgot-password-cta">
                                        <span type="semibold" className="ActionLink CallToAction">¿Olvidaste tu contraseña?</span>
                                    </ActionLink>
                                </ForgotPassword>
                                {error && (
                                    <TextError>
                                        {error} {/* Accede a la propiedad "message" del objeto de error */}
                                    </TextError>
                                )}
                                {errorPrevent &&
                                    <TextError>
                                        {errorPrevent} {/* Accede a la propiedad "message" del objeto de error */}
                                    </TextError>
                                }
                            </AuthLayout>
                        </ContentSpaceForLogin>
                    </SpaceForLogin>
                    <Footer>
                        <FooterText>
                            Copyright © 2023 Mapps Inc. Todos los derechos reservados.
                        </FooterText>
                    </Footer>
                </LoginLayaout>
            </ContainerLogin>
        </BodyLogin>
    );
};
